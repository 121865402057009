import styled from 'styled-components';

import { grid } from '../constants/grid';

const BR = styled.br`
    @media screen and (max-width: ${grid.breakpoints.sm - 1}px) {
        display: none;
    }
`

export default BR;
