import styled from 'styled-components';

import { grid } from '../../constants/grid';

const Container = styled.div`
    padding-left: ${grid.gutter / 2}px;
    padding-right: ${grid.gutter / 2}px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: ${grid.breakpoints.xs}px) {
        width: ${grid.containers.xs}px;
    }
    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        width: ${grid.containers.sm}px;
    }
    @media screen and (min-width: ${grid.breakpoints.md}px) {
        width: ${grid.containers.md}px;
    }
    @media screen and (min-width: ${grid.breakpoints.lg}px) {
        width: ${grid.containers.lg}px;
    }
`

const ContainerWide = styled.div`
    padding-left: ${grid.gutter / 2}px;
    padding-right: ${grid.gutter / 2}px;
    margin-left: auto;
    margin-right: auto;

    @media screen and (min-width: ${grid.breakpoints.xs}px) {
        width: ${grid.wideContainers.xs}px;
    }
    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        width: ${grid.wideContainers.sm}px;
    }
    @media screen and (min-width: ${grid.breakpoints.md}px) {
        width: ${grid.wideContainers.md}px;
    }
    @media screen and (min-width: ${grid.breakpoints.lg}px) {
        width: ${grid.wideContainers.lg}px;
    }
`

const Row = styled.div`
    margin-left: ${grid.gutter / -2}px;
    margin-right: ${grid.gutter / -2}px;
    display: flex;
    flex-wrap: wrap;
`

const Column = styled.div`
    min-height: 1px;
    width: 100%;
    flex: 0 0 auto;
    padding-left: ${grid.gutter / 2}px;
    padding-right: ${grid.gutter / 2}px;

    @media screen and (min-width: ${grid.breakpoints.xs}px) {
        flex-basis: ${props => `${(100 / 12) * props.xs}%`};
        max-width: ${props => `${(100 / 12) * props.xs}%`};
        margin-left: ${props => `${(100 / 12) * props.offsetXs}%`};
    }
    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        flex-basis: ${props => `${(100 / 12) * props.sm}%`};
        max-width: ${props => `${(100 / 12) * props.sm}%`};
        margin-left: ${props => `${(100 / 12) * props.offsetSm}%`};
    }
    @media screen and (min-width: ${grid.breakpoints.md}px) {
        flex-basis: ${props => `${(100 / 12) * props.md}%`};
        max-width: ${props => `${(100 / 12) * props.md}%`};
        margin-left: ${props => `${(100 / 12) * props.offsetMd}%`};
    }
    @media screen and (min-width: ${grid.breakpoints.lg}px) {
        flex-basis: ${props => `${(100 / 12) * props.lg}%`};
        max-width: ${props => `${(100 / 12) * props.lg}%`};
        margin-left: ${props => `${(100 / 12) * props.offsetLg}%`};
    }
`

export { Container, ContainerWide, Row, Column };
