import React from 'react';
import PropTypes from 'prop-types';
import { useStaticQuery, graphql } from 'gatsby';

import Header from './Header/Header';
import SocialMedia from './SocialMedia/SocialMedia';
import Footer from './Footer/Footer';

import GlobalStyle from './GlobalStyle';

const Layout = ({ children }) => {
    const data = useStaticQuery(graphql`
        query SiteTitleQuery {
            site {
                siteMetadata {
                    title
                    nav {
                        to
                        label
                    }
                }
            }
        }
    `);
    return (
        <>

            <GlobalStyle />

            <Header
                siteName={data.site.siteMetadata.title}
                navMenuItems={data.site.siteMetadata.nav}
            />

            <main role="main">

                {children}

                <SocialMedia />

            </main>

            <Footer
                siteName={data.site.siteMetadata.title}
            />

        </>
    );
};

Layout.propTypes = {
    children: PropTypes.node.isRequired,
};

export default Layout;
