import { createGlobalStyle } from 'styled-components';
import { normalize } from 'styled-normalize';

import { colors } from '../constants/colors';
import { typography } from '../constants/typography';
import { grid } from '../constants/grid';

const GlobalStyle = createGlobalStyle`
    ${normalize}

    html {
        box-sizing: border-box;
    }

    *,
    *:before,
    *:after {
        box-sizing: inherit;
    }

    body {
        font-family: ${typography.base.font};
        font-size: ${typography.base.size}px;
        font-weight: ${typography.base.weight};
        line-height: ${typography.base.lineHeight};
        color: ${colors.grays.darkest};
    }

    img {
        max-width: 100%;
        height: auto;
    }

    svg {
        fill: currentColor;
    }

    h1, h2, h3, h4, h5, h6, ol, ul, p, table, pre {
        margin-top: 0;
    }

    h1, h2, h3, h4, h5, h6 {
        font-family: ${typography.headings.font};
        font-weight: ${typography.headings.weight};
        line-height: ${typography.headings.lineHeight};
        color: inherit;
    }

    h1 { font-size: ${typography.headings.sizes.h2 / 1.5}px; }
    h2 { font-size: ${typography.headings.sizes.h2 / 1.5}px; letter-spacing: -.25px; }
    h3 { font-size: ${typography.headings.sizes.h3 / 1.5}px; color: ${colors.base.green}; letter-spacing: -.25px; }
    h4 { font-size: ${typography.headings.sizes.h4 / 1.5}px; }
    h5 { font-size: ${typography.headings.sizes.h5 / 1.5}px; }
    h6 { font-size: ${typography.headings.sizes.h6 / 1.5}px; }

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        h1 { font-size: ${typography.headings.sizes.h2}px; }
        h2 { font-size: ${typography.headings.sizes.h2}px; }
        h3 { font-size: ${typography.headings.sizes.h3}px; }
        h4 { font-size: ${typography.headings.sizes.h4}px; }
        h5 { font-size: ${typography.headings.sizes.h5}px; }
        h6 { font-size: ${typography.headings.sizes.h6}px; }
    }
`

export default GlobalStyle;
