const systemFontStack = `-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif`;

const typography = {
    base: {
        size: 17,
        lineHeight: 1.5,
        font: `Lato, ${systemFontStack}`,
        weight: `normal`,
    },
    headings: {
        sizes: {
            h1: 60,
            h2: 44,
            h3: 44,
            h4: 36,
            h5: 24,
            h6: 20,
        },
        lineHeight: 1.15,
        font: `'Noto Serif', ${systemFontStack}`,
        weight: `bold`,
    }
}

export { typography };
