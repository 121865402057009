import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { sizes } from '../../constants/sizes';

const colorCombinations = {
    white: {
        background: `#ffffff`,
    },
    blue: {
        background: colors.base.blue,
        text: `#ffffff`,
    },
    green: {
        background: colors.base.green,
        text: `#ffffff`,
    },
    gray: {
        background: colors.grays.medium,
        text: `#ffffff`,
    },
    darkGray: {
        background: colors.grays.darker,
        text: `#ffffff`,
    }
}

const Band = styled.div`
    background-color: ${props => colorCombinations[props.theme].background || `#ffffff`};
    color: ${props => colorCombinations[props.theme].text};
    padding-top: ${props => {
        if (null !== props.pt) {
            return `${props.pt}px`;
        }
        return `${sizes[props.size]}px`;
    }};
    padding-bottom: ${props => {
        if (null !== props.pb) {
            return `${props.pb}px`;
        }
        return `${sizes[props.size]}px`;
    }};
    text-align: ${props => props.center ? `center` : null};

    p:last-child, h2:last-child, h3:last-child {
        margin-bottom: 0;
    }
`

Band.defaultProps = {
    theme: `white`,
    size: null,
    center: true,
    pb: null,
    pt: null,
};

export default Band;
