import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';

import Band from '../Band/Band';
import { Container } from '../Grid/Grid';
import SR from '../SR';

import { typography } from '../../constants/typography';
import { useStaticQuery, graphql } from 'gatsby';
import { grid } from '../../constants/grid';

const BandHeading = styled.h2`
    font-size: ${typography.headings.sizes.h6}px;

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        font-size: ${typography.headings.sizes.h4}px;
    }
`

const SocialAccounts = styled.div`
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
`

const BasicSocialAccount = ({ className, to, children, image }) => (
    <a href={to} target="_blank" rel="noopener noreferrer" className={className}>
        {image && <Img fluid={image.childImageSharp.fluid} />}
        <Overlay>
            {children}
        </Overlay>
    </a>
);

const SocialAccount = styled(BasicSocialAccount)`
    margin: 0 5px 10px 5px;
    background-color: #BBBDC0;
    display: block;
    position: relative;
    color: #ffffff;
    flex: 0 0 100%;
    flex-basis: calc(33.333333% - 10px);
    max-width: calc(33.333333% - 10px);

    ${({ image }) => !image ? `
        &::before {
            content: '';
            display: block;
            padding-bottom: 133.75%;
            height: 0;
            width: 100%;
        }
    ` : null}

    @media screen and (min-width: ${grid.breakpoints.xs}px) {
        flex-basis: 100px;
        max-width: 100px;
    }
    
    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        flex-basis: 160px;
        max-width: 160px;
    }
`

const SocialMedia = () => {
    const accounts = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    social {
                        twitter
                        linkedin
                        youtube
                    }
                }
            }
            twitterBackground: file(relativePath: { eq: "social-clouds.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 320) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            linkedinBackground: file(relativePath: { eq: "social-gray.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 320) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
            youtubeBackground: file(relativePath: { eq: "social-leaves.jpg" }) {
                childImageSharp {
                    fluid(maxWidth: 320) {
                        ...GatsbyImageSharpFluid_withWebp
                    }
                }
            }
        }
    `);
    const { social } = accounts.site.siteMetadata;
    return (
        <Band size="md" pt={0}>
            <Container>
                <BandHeading>Follow us on social media</BandHeading>
                <SocialAccounts>
                    {social.twitter && (
                        <SocialAccount to={social.twitter} image={accounts.twitterBackground}>
                            <SR>Twitter</SR>
                        </SocialAccount>
                    )}
                    {social.linkedin && (
                        <SocialAccount to={social.linkedin} image={accounts.linkedinBackground}>
                            <SR>Linkedin</SR>
                        </SocialAccount>
                    )}
                    {social.youtube && (
                        <SocialAccount to={social.youtube} image={accounts.youtubeBackground}>
                            <SR>YouTube</SR>
                        </SocialAccount>
                    )}
                </SocialAccounts>
            </Container>
        </Band>
    );
};

export default SocialMedia;
