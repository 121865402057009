import React from 'react';
import styled from 'styled-components';

import { graphql } from 'gatsby';
import Img from 'gatsby-image/withIEPolyfill';
import { grid } from '../../constants/grid';

const BasicCover = ({ image, className }) => {
    return (
        <div className={className}>
            <Img fluid={image.childImageSharp.fluid} />
        </div>
    );
};

const Cover = styled(BasicCover)`
    @media screen and (max-width: ${grid.breakpoints.sm - 1}px) {
        .gatsby-image-wrapper > div[style*="padding-bottom"] {
            padding-bottom: 100% !important;
        }
    }
`

export const coverImageFragment = graphql`
    fragment coverImageFragment on File {
        childImageSharp {
            fluid(maxWidth: 1920) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`

export default Cover;
