import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'gatsby';
import styled from 'styled-components';

import { ContainerWide } from '../Grid/Grid';
import Nav, { NavToggle } from './Nav';

import { sizes } from '../../constants/sizes';
import { grid } from '../../constants/grid';

import siteLogo from '../../images/header-logo.svg';

const BasicLogo = ({ className, siteName }) => (
    <Link className={className} to="/">
        <img src={siteLogo} alt={siteName} />
    </Link>
);

const Logo = styled(BasicLogo)`
    display: block;
    width: 160px;
    margin: 0 auto 0 0;

    img {
        display: block;
        margin: 0;
    }

    @media screen and (min-width: 520px) {
        margin-left: auto;
    }

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        margin: 0;
    }
`;

const HeaderContainer = styled(ContainerWide)`
    display: flex;
    justify-content: space-between;
    align-items: flex-start;
    flex-wrap: wrap;

    @media screen and (min-width: ${grid.breakpoints.md}px) {
        align-items: center;
    }
`
class BasicHeader extends React.Component {
    constructor() {
        super(...arguments);
        this.state = { isMenuOpen: false };
        this.doNavbarToggle = this.doNavbarToggle.bind(this);
    }
    componentDidMount() {
        document.addEventListener('opr_route_change', (e) => {
            this.setState({ isMenuOpen: false });
        });
    }
    doNavbarToggle() {
        this.setState({
            isMenuOpen: !this.state.isMenuOpen
        });
    }
    render() {
        return (
            <header role="banner" className={this.props.className}>
                <HeaderContainer>
                    <Logo siteName={this.props.siteName} />
                    <NavToggle doNavbarToggle={this.doNavbarToggle} isMenuOpen={this.state.isMenuOpen} />
                    <Nav menuItems={this.props.navMenuItems} isMenuOpen={this.state.isMenuOpen} />
                </HeaderContainer>
            </header>
        );
    }
}

const Header = styled(BasicHeader)`
    padding: ${sizes.xs}px 0 0;
    background-color: #ffffff;
    text-align: center;

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        text-align: left;
    }
`

Header.propTypes = {
    siteName: PropTypes.string.isRequired,
    navMenuItems: PropTypes.array.isRequired,
};

export default Header;
