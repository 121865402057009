const grid = {
    gutter: 30,
    columns: 12,
    containers: {
        xs: `auto`,
        sm: 750,
        md: 960,
        lg: 960,
    },
    wideContainers: {
        xs: `auto`,
        sm: 750,
        md: 970,
        lg: 1260,
    },
    breakpoints: {
        xs: 380,
        sm: 768,
        md: 992,
        lg: 1320,
    }
};

export { grid };
