const colors = {
    base: {
        blue: `#4A80AE`,
        green: `#2BB673`,
    },
    grays: {
        light: `#E9E9E9`,
        medium: `#A7A9AC`,
        dark: `#6C6E70`,
        darker: `#58595B`,
        darkest: `#231F20`,
    },
}

export { colors };
