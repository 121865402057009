import React from 'react';
import Helmet from 'react-helmet';
import { useStaticQuery, graphql } from 'gatsby';

const SEO = ({ title, meta = [], description }) => {
    const { site } = useStaticQuery(graphql`
        query {
            site {
                siteMetadata {
                    title
                }
            }
        }
    `)
    return (
        <Helmet
            htmlAttributes={{
                lang: `en`,
            }}
            title={title}
            titleTemplate={`%s | ${site.siteMetadata.title}`}
            meta={[
                {
                    property: `og:title`,
                    content: title,
                },
                {
                    property: `og:type`,
                    content: `website`,
                },
                {
                    name: `twitter:title`,
                    content: title,
                },
                {
                    name: `description`,
                    content: description,
                },
                {
                    property: `og:description`,
                    content: description,
                },
            ].concat(meta)}
        />
    );
};

export default SEO;
