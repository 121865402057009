import React from 'react';
import styled from 'styled-components';

import { sizes } from '../../constants/sizes';
import { colors } from '../../constants/colors';
import { grid } from '../../constants/grid';

import siteLogo from '../../images/footer-logo.svg';
import { Container, Row, Column } from '../Grid/Grid';
import { Link } from 'gatsby';

const Logo = styled.img`
    display: block;
    margin: 0 auto ${sizes.sm}px;
    max-width: 260px;

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        margin-bottom: 0;
    }
`

const FooterAbbr = styled.abbr`
    font-weight: bold;
    text-decoration: none !important;
    border-bottom: none;
`

const FooterCopyright = styled.p`
    font-size: 14px;
    margin-top: 15px;
    line-height: 1;
`

const FooterTextColumn = styled(Column)`
    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        display: flex;
        align-items: flex-end;
    }
`

const FooterText = styled.div`
    
`

const BasicFooter = ({ className, siteName }) => {
    return (
        <footer role="contentinfo" className={className}>
            <Container>
                <Row>
                    <Column sm={4} lg={3}>
                        <Logo src={siteLogo} alt="" />
                    </Column>
                    <FooterTextColumn sm={6} lg={8} offsetLg={1}>
                        <FooterText>
                            <p>{siteName}</p>
                            <p><FooterAbbr title="Telephone">T</FooterAbbr> <a href="tel:+447722807092">+44 (0)7722 807092</a></p>
                            <p><FooterAbbr title="E-Mail">E</FooterAbbr> <a href="mailto:fiona.wright@oneplanetretail.com">fiona.wright@oneplanetretail.com</a></p>
                            <p><Link to="/terms">Terms and conditions</Link></p>
                            <FooterCopyright>Copyright {new Date().getFullYear()} {siteName} | All Rights Reserved</FooterCopyright>
                        </FooterText>
                    </FooterTextColumn>
                </Row>
            </Container>
        </footer>
    );
};

const Footer = styled(BasicFooter)`
    background-color: ${colors.grays.darker};
    padding: ${sizes.md}px 0;
    color: #ffffff;
    text-align: center;

    a {
        color: inherit;
        text-decoration: none;
    }

    p {
        margin-bottom: 0;
    }

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        text-align: left;
    }
`;

export default Footer;
