import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { typography } from '../../constants/typography';

const Button = styled.a`
    display: inline-block;
    cursor: pointer;
    background-color: ${colors.base.green};
    color: #ffffff;
    text-transform: uppercase;
    text-decoration: none;
    border: none;
    border-radius: 0;
    padding: 0;
    text-align: center;
    line-height: 1;
    font-size: ${typography.base.size}px;
    padding: 10px 40px;
    min-width: 220px;
    font-family: ${typography.base.font};
    letter-spacing: 1.5px;

    &:hover, &:focus {
        background-color: ${colors.grays.medium};
    }
`

export default Button;