import React from 'react';
import styled from 'styled-components';
import Img from 'gatsby-image/withIEPolyfill';
import Button from '../Button/Button';

import { colors } from '../../constants/colors';
import { grid } from '../../constants/grid';
import { typography } from '../../constants/typography';

const colorCombinations = {
    white: {
        background: `#ffffff`,
    },
    blue: {
        background: colors.base.blue,
        text: `#ffffff`,
    },
    green: {
        background: colors.base.green,
        text: `#ffffff`,
    },
    gray: {
        background: colors.grays.medium,
        text: `#ffffff`,
    },
    darkGray: {
        background: colors.grays.darker,
        text: `#ffffff`,
    }
}


const BasicCardOverlayTitle = ({ className, children }) => (
    <div className={className}>
        <div>
            <h1>{children}</h1>
        </div>
    </div>
);

const CardOverlayTitle = styled(BasicCardOverlayTitle)`
    padding: 40px 40px 15% 40px;
    height: 100%;
    font-size: calc(100vw / 20);
    display: flex;
    align-items: flex-end;
    justify-content: center;

    h1 {
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 0;
        text-align: center;
        font-family: ${typography.base.font};
        letter-spacing: 1.5px;
        border: 1px solid currentColor;
        font-size: calc(100vw / 25);
        padding: calc(100vw / 90) calc(100vw / 25);
    }

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        padding: 15px 25px;
        font-size: 16px;
        h1 {
            font-size: 18px;
            margin-bottom: 15px;
            padding: 3px 20px;
        }
    }

    @media screen and (min-width: ${grid.breakpoints.md}px) {
        padding: 30px;
        h1 {
            width: 200px;
        }
    }

    @media screen and (min-width: ${grid.breakpoints.lg}px) {
        padding: 35px;
        font-size: 24px;
        h1 {
            font-size: 22px;
            margin-bottom: 25px;
            padding: 5px 15px;
        }
    }
`

// ----

const BasicBrandBuyerCardOverlay = ({ className, children, logo, heading }) => (
    <div className={className}>
        <div>
            {logo}
            <h2>{heading}</h2>
            {children}
        </div>
    </div>
);

const BrandBuyerCardOverlay = styled(BasicBrandBuyerCardOverlay)`
    padding: 30px;
    height: 100%;
    font-size: calc(100vw / 20);
    display: flex;
    align-items: center;

    h2 {
        font-size: calc(100vw / 10);
    }

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        padding: 20px;
        font-size: 14px;
        h2 {
            font-size: 24px;
            margin-bottom: 15px;
        }
        svg {
            width: 160px;
        }
    }

    @media screen and (min-width: ${grid.breakpoints.sm}px) and (max-width: ${grid.breakpoints.md - 1}px) {
        ${Button} {
            font-size: 14px;
            padding-left: 10px;
            padding-right: 10px;
            min-width: 0;
        }
    }

    @media screen and (min-width: ${grid.breakpoints.md}px) {
        padding: 30px;
        font-size: 14px;
        h2 {
            font-size: 30px;
            margin-bottom: 15px;
        }
        svg {
            width: 220px;
        }
    }

    @media screen and (min-width: ${grid.breakpoints.lg}px) {
        font-size: inherit;
        padding: 40px;
        h2 {
            font-size: 40px;
            margin-bottom: 25px;
            line-height: 1.15;
        }
        svg {
            width: 280px;
        }
    }
`

// ----

const BasicCardOverlayLargeText = ({ className, children }) => (
    <div className={className}>{children}</div>
);

const CardOverlayLargeText = styled(BasicCardOverlayLargeText)`
    padding: 35px;
    color: #ffffff;
    height: 100%;
    display: flex;
    align-items: center;
    font-size: calc(100vw / 12);
    line-height: 1.1;
    text-align: left;
    font-family: ${typography.headings.font};
    font-weight: ${typography.headings.weight};

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        padding: 25px;
        font-size: 22px;
    }

    @media screen and (min-width: ${grid.breakpoints.lg}px) {
        padding: 45px;
        font-size: 40px;
    }
`

// ----

const BasicContactCardOverlay = ({ className, children, description }) => (
    <div className={className}>
        <div>
            <h1>{children}</h1>
            <p>{description}</p>
        </div>
    </div>
);

const ContactCardOverlay = styled(BasicContactCardOverlay)`
    padding: 40px 40px 35% 40px;
    height: 100%;
    font-size: calc(100vw / 20);
    
    h1 {
        text-transform: uppercase;
        font-weight: normal;
        margin-bottom: 0;
        text-align: center;
        font-family: ${typography.base.font};
        letter-spacing: 1.5px;
        border: 1px solid currentColor;
        font-size: calc(100vw / 25);
        padding: 5px 15px;
        margin-bottom: 25px;
        width: 65%;
    }
    div {
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: flex-start;
    }
    p {
        text-align: left;
        line-height: 1.15;
        font-family: ${typography.headings.font};
        width: 65%;
    }

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        padding: 25px 25px 35% 25px;
        font-size: 16px;
        h1 {
            font-size: 18px;
            margin-bottom: 15px;
            padding: 3px 15px;
        }

        h1, p {
            width: auto;
        }
    }

    @media screen and (min-width: ${grid.breakpoints.md}px) {
        padding: 45px 45px 34% 45px;
        font-size: 25px;
        h1 {
            font-size: 22px;
            margin-bottom: 25px;
            padding: 5px 15px;
        }
        h1, p {
            width: 204px;
        }
    }
`

// ----

const BannerCards = styled.div`
    margin-left: -2px;
    margin-right: -2px;
    
    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        display: flex;
        margin-left: ${grid.gutter / -2}px;
        margin-right: ${grid.gutter / -2}px;
    }
`
const BannerCardsColumn = styled.div`
    min-height: 1px;
    padding: 0 2px;
    margin-bottom: 10px;

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        padding-left: ${grid.gutter / 2}px;
        padding-right: ${grid.gutter / 2}px;
        margin-bottom: 0;
        flex: 0 0 ${100 / 3}%;
        max-width: ${100 / 3}%;
    }
`

const Overlay = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10;
`

const BasicCard = ({ className, image, children }) => (
    <BannerCardsColumn>
        <div className={className}>
            {image && (
                image.childImageSharp ? <Img fluid={image.childImageSharp.fluid} /> : <img src={image} alt="" />
            )}
            {children && <Overlay>{children}</Overlay>}
        </div>
    </BannerCardsColumn>
);

const Card = styled(BasicCard)`
    background-color: ${props => colorCombinations[props.theme].background || `#ffffff`};
    color: ${props => colorCombinations[props.theme].text};
    box-shadow: ${props => props.theme === `white` && `inset 0 0 1px 1px #000`};
    position: relative;

    ${({ image }) => !image ? `
        &::before {
            content: '';
            display: block;
            padding-bottom: 135.4%;
            height: 0;
            width: 100%;
        }
    ` : null}

    img {
        display: block;
    }
`

export const cardImageFragment = graphql`
    fragment cardImageFragment on File {
        childImageSharp {
            fluid(maxWidth: 768) {
                ...GatsbyImageSharpFluid_withWebp
            }
        }
    }
`

Card.defaultProps = {
    theme: `white`
};

export { Card, CardOverlayTitle, CardOverlayLargeText, ContactCardOverlay, BrandBuyerCardOverlay };
export default BannerCards;
