import React from 'react';
import styled from 'styled-components';

const Chevron = styled.div`
    margin-top: 20px;
    
    & > svg {
        width: 21px;
        height: 24px;
    }
`

const BasicDiscoverMore = ({ children, id, className }) => (
    <div className={className} id={id}>
        {children}
        <Chevron>
            <svg aria-hidden="true" focusable="false" role="img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 448 512"><path d="M443.5 162.6l-7.1-7.1c-4.7-4.7-12.3-4.7-17 0L224 351 28.5 155.5c-4.7-4.7-12.3-4.7-17 0l-7.1 7.1c-4.7 4.7-4.7 12.3 0 17l211 211.1c4.7 4.7 12.3 4.7 17 0l211-211.1c4.8-4.7 4.8-12.3.1-17z"></path></svg>
        </Chevron>
    </div>
);

const DiscoverMore = styled(BasicDiscoverMore)`
    font-size: 22px;
`

export default DiscoverMore;
