import React from 'react';
import PropTypes from 'prop-types';

import { Link } from 'gatsby';
import styled from 'styled-components';

import { colors } from '../../constants/colors';
import { grid } from '../../constants/grid';
import { sizes } from '../../constants/sizes';

const BasicNav = ({ menuItems, isMenuOpen, ...props }) => (
    <nav role="navigation" {...props}>
        <NavMenu isMenuOpen={isMenuOpen}>
            {menuItems.map((i, k) => (
                <NavItem key={k}>
                    <Link to={i.to} activeStyle={{
                        color: colors.grays.darkest,
                        borderBottomColor: colors.base.green,
                        fontWeight: `bold`,
                    }}>{i.label}</Link>
                </NavItem>
            ))}
        </NavMenu>
    </nav>
);

const Nav = styled(BasicNav)`
    text-transform: uppercase;
    font-size: 12px;
    color: ${colors.grays.darker};
    line-height: 1;
    display: block;
    flex: 1 0 100%;
    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        flex: 0 0 auto;
    }
`

const NavMenu = styled.ul`
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
    overflow: hidden;
    @media screen and (max-width: 519px) {
        height: ${props => props.isMenuOpen ? `auto` : 0};
        margin-top: ${props => props.isMenuOpen ? `${sizes.xs}px` : null};
    }
`

const NavItem = styled.li`
    margin-bottom: 10px;
    letter-spacing: .6px;

    & > a {
        text-decoration: none;
        color: inherit;
        display: block;
        padding: 3px 0 1px 0;
        border-bottom: none;
        
        &:hover, &:focus {
            color: ${colors.grays.darkest};
        }
    }

    @media screen and (min-width: 520px) {
        display: inline-block;
        margin-bottom: 0;

        &:not(:first-child) {
            margin-left: 20px;
        }

        & > a {
            border-bottom: 2px solid transparent;
        }
    }

    @media screen and (min-width: ${grid.breakpoints.sm}px) {
        &:not(:first-child) {
            margin-left: 60px;
        }
    }
`

const BasicNavToggle = ({ isMenuOpen, doNavbarToggle, className }) => {
    return (
        <button onClick={doNavbarToggle} className={className}>
            {isMenuOpen
                ? (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 21 21"><g stroke="#868686" strokeWidth="2.83" fill="none"><path d="M19.89 1.15L1.34 19.7M1.34 1.15L19.89 19.7" /></g></svg>
                ) : (
                    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 27 20"><g stroke="#868686" strokeWidth="2.83" fill="none"><path d="M26.48 18.1H.25M26.48 9.76H.25M26.48 1.42H.25" /></g></svg>
                )}
        </button>
    );
};

const NavToggle = styled(BasicNavToggle)`
    text-transform: lowercase;
    font-size: 12px;
    color: ${colors.grays.darker};
    line-height: 1;
    border: none;
    padding: 0;
    background-color: transparent;
    display: block;
    margin: 0 0 0 auto;

    svg {
        width: 20px;
    }

    @media screen and (min-width: 520px) {
        display: none;
    }
`

Nav.propTypes = {
    menuItems: PropTypes.array.isRequired,
};

export { NavToggle };
export default Nav;
